<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="editUser()">
          <div class="card-header">
            <h4>Edit User</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="fields.name" placeholder="Christopher Okokon"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control" disabled v-model="fields.email" placeholder="christopher.okokon@asolarnig.com"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Phone number</label>
                  <input type="text" class="form-control" v-model="fields.phone" placeholder="0818 000 0000"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Category</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.category">
                      <option value="" disabled>Select an option</option>
                      <option value="employee">Employee</option>
                      <option value="admin">Admin</option>
                      <option value="agent">Agent</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Activate User</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.status">
                      <option value="" disabled>Select an option</option>
                      <option value="active">Activate</option>
                      <option value="inactive">Deactivate</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between pt-0">
            <button class="btn btn-warning" :class="{ 'btn-progress': loading }" type="button" @click.prevent="revertUser()">Cancel</button>
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SettingsHelpers from "@/functions/actions/SettingsRequest";
export default {
  name: "editUser",
  computed: {
    ...mapState(["loading"])
  },
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  },
  mounted() {
    this.fetchUser(this.userId)
  },
  data() {
    return {
      fields: {
        category: "",
        status: ""
      },
    };
  },
  methods: {
    revertUser() {
      this.$router.go(-1)
    },
    async fetchUser(userId) {
      const response = await SettingsHelpers.fetchUser(userId);
      if (response.status) {
        this.fields = response.user
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}`,
          "error"
        );
        this.revertUser()
      }
    },
    async editUser() {
      if (this.validateData()) {
        const response = await SettingsHelpers.editUser(this.fields);
        if (response.status) {
          this.showAlert("Success", "User information updated successfully", "success")
          this.$router.push({
            name: "All Users"
          })
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.name) {
        if (this.fields.email) {
          if (this.fields.phone) {
            if (this.fields.category) {
              return true
            } else {
              this.showAlert(
                "Validation error",
                "Select a user category",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Provide a phone number",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Provide an email address",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Provide the user\'s name",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>